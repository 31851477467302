import { Controller } from '@hotwired/stimulus';
import Modal from 'bootstrap/js/dist/modal';

import { events, subscribe } from 'lib/event_bus';

/**
 * Display a Bootstrap modal -
 * This controller simply provides a light wrapper for Bootstrap's jQuery-based modals.
 *
 * Modals may be triggered from anywhere in the application via the event bus interface, e.g.:
 *
 *   const params = {
 *     targetId: 'app-modal',
 *     title: 'warning',
 *     content: 'simian event likely',
 *   };
 *   publish(events.MODAL_DISPLAY, params);
 */

export default class extends Controller {
  static targets = ['title', 'content', 'okButton'];

  static values = {
    // set to true to show on page load
    show: Boolean,
    // If set, then metrics for the modal are published to plausible when the
    // modal is shown and hidden. For this, two goals need to be registered
    // in plausible ({metric}_shown and {metric}_hidden). The goals can
    // be registered at https://plausible.io/frontporchforum.com/settings/goals
    // and https://plausible.io/staging.frontporchforum.com/settings/goals.
    metric: String,
  };

  // Processes the event by sending it as a custom metric to plausible along
  // with the props for the modal ID and the path as properties to further
  // breakdown the goal in Plausible.
  sendMetric(e) {
    if (!this.metricValue || !window.plausible) return;

    const registeredMetric = `${this.metricValue}_${e.type}`;

    window.plausible(registeredMetric, {
      props: {
        path: document.location.pathname,
        modal: e.target.id,
      },
    });
  }

  connect() {
    this.modal = new Modal(this.element);

    const callback = (event) =>
      event.detail.targetId === this.element.id && this.alert(event.detail);
    subscribe(events.MODAL_DISPLAY, callback, {});

    const self = this;
    $(this.element).on('shown.bs.modal', (e) => {
      self.sendMetric(e);
    });

    $(this.element).on('hidden.bs.modal', (e) => {
      self.sendMetric(e);
    });

    if (this.showValue) {
      this.modal.show();
    }
  }

  disconnect() {
    this.modal.dispose();
  }

  alert(params) {
    if (params.title) this.titleTarget.textContent = params.title;
    if (params.content) this.contentTarget.textContent = params.content;
    this.modal.show();
  }

  /**
   * Triggered when the ok button is clicked. By default, just hides the modal,
   * but a subclass may want to override that behavior, e.g. to call an API method.
   */
  ok() {
    this.modal.hide();
  }

  /**
   * Triggered when the cancel button is clicked. By default, hides the modal.
   */
  cancel() {
    this.modal.hide();
  }

  /**
   * Update the form to show that it's submitting (or not).
   *
   * @param submitting {Boolean}
   */
  toggleSubmitting(submitting) {
    if (this.hasOkButtonTarget) {
      this.okButtonTarget.disabled = submitting;
    }
  }

  /**
   * Return the CSRF authentication token from a document meta tag.
   */
  get csrfToken() {
    return document.querySelector('meta[name=csrf-token]')?.content;
  }

  // Convenience method to access an elements data attribute for the stimulus controller
  // rather than accessing it through the elements dataset property
  // (the stimulus value concept does not work when iterating over the target elements)
  elementValue(element, attributeName) {
    return element.getAttribute(
      `data-${this.identifier}-${attributeName}-value`,
    );
  }
}
