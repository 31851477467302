import { Controller } from '@hotwired/stimulus';

import { publish, events } from 'lib/event_bus';
import { spinnerToggle } from 'components/spinner/spinner';

const idAttr = 'data-profiles--closest-areas-id-value';

/**
 * profiles--closest-areas controller: connect address fields with area selection
 * fields and map.  Used by user registration, profile create, profile edit.
 *
 * States:
 * - editingAddress
 *    - enter this state when an address field is changed
 *    - hide next / save button
 *    - show find area button
 * - selectingArea
 *    - enter this state when areas are selectable
 *    - disable next / save button if no areas are selected
 */
export default class extends Controller {
  static targets = [
    'profilePlanField',
    'streetNumberField',
    'streetNameField',
    'cityField',
    'stateField',
    'saveButton',
    'searchButton',
    'areasContainer',
    'areaContainer',
    'areaField',
    'mapContainer',
  ];

  static values = {
    canEditAddress: Boolean,
    closestAreasUrl: String,
    editingAddress: Boolean,
  };

  connect() {
    this.toggleEditingAddress(this.editingAddressValue);
  }

  startEditingAddress() {
    this.toggleEditingAddress(true);
  }

  toggleEditingAddress(editing) {
    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.classList.toggle('show', !editing);
      this.toggleSaveDisabled(editing);
    }
    if (this.hasAreasContainerTarget) {
      this.areasContainerTarget.classList.toggle('show', !editing);
    }
    if (this.hasSearchButtonTarget) {
      this.searchButtonTarget.classList.toggle('show', editing);
    }
  }

  search() {
    const data = {
      profile: {
        profile_plan_id: this.profilePlanFieldTarget.value,
      },
      address: {
        street_number: this.streetNumberFieldTarget.value,
        street_name: this.streetNameFieldTarget.value,
        city: this.cityFieldTarget.value,
        state: this.stateFieldTarget.value,
      },
    };

    this.toggleSearching(true);

    $.ajax({
      type: 'GET',
      url: this.closestAreasUrlValue,
      data,
    })
      .done(this.showSearchResults.bind(this))
      .always(() => {
        this.toggleSearching(false);
      });
  }

  toggleSearching(searching) {
    spinnerToggle(this.searchButtonTarget, searching, { type: 'button' });
  }

  showSearchResults(response) {
    this.areasContainerTarget.innerHTML = response;
    this.toggleEditingAddress(false);
    this.toggleSaveDisabled();
  }

  onClickMapArea(event) {
    this.toggleAreaSelected(event.detail.id);
  }

  onMouseoverMapArea(event) {
    const container = this.findAreaContainerById(event.detail.id);
    if (container) {
      container.classList.add('card-hover');
    }
  }

  onMouseoutMapArea(event) {
    const container = this.findAreaContainerById(event.detail.id);
    if (container) {
      container.classList.remove('card-hover');
    }
  }

  onAreaFieldChange(event) {
    if (event.target.type === 'radio') {
      publish(events.MAP_CLEAR_POLYGONS_SELECTED, {}, { on: this.mapTarget });
    }

    this.areaSelected(event.target.value);
  }

  toggleAreaSelected(polygonKey) {
    const areaId = Number(polygonKey.split('-')[1]);
    const field = this.findAreaFieldById(areaId);
    if (field && field.type === 'checkbox') {
      field.checked = !field.checked;

      this.areaSelected(areaId);
    }

    if (field && field.type === 'radio') {
      field.checked = true;

      this.areaSelected(areaId);
    }
  }

  areaSelected(areaId) {
    this.toggleAreaContainerSelected(areaId);

    if (this.hasMapContainerTarget) {
      const data = { id: `area-${areaId}` };
      publish(events.MAP_TOGGLE_POLYGON_SELECTED, data, { on: this.mapTarget });
    }

    this.toggleSaveDisabled();
  }

  toggleAreaContainerSelected(areaId) {
    const field = this.findAreaFieldById(areaId);
    const container = this.findAreaContainerById(areaId);
    if (field && container) {
      container.classList.toggle('card-selected', field.checked);
    }
  }

  toggleSaveDisabled(editing) {
    if (this.hasSaveButtonTarget && this.canEditAddressValue) {
      const disabled = editing || !this.selectedAreaFields.length;
      this.saveButtonTarget.disabled = disabled;
    }
  }

  findAreaFieldById(areaId) {
    const areaIdStr = `${areaId}`;
    return this.areaFieldTargets.find((field) => field.value === areaIdStr);
  }

  findAreaContainerById(areaId) {
    const areaIdStr = `${areaId}`;
    return this.areaContainerTargets.find(
      (el) => el.getAttribute(idAttr) === areaIdStr,
    );
  }

  /**
   * Returns an array of all area fields that are selected (hidden or checked).
   */
  get selectedAreaFields() {
    return this.areaFieldTargets.filter(
      (field) => field.type === 'hidden' || field.checked,
    );
  }

  get mapTarget() {
    return this.mapContainerTarget.children[0];
  }
}
